import strings from './getRollupText.locstring.json';
import type InboxViewType from 'owa-service/lib/contract/InboxViewType';
import { isFolderPausedInbox } from 'owa-mail-list-store';
import { getStore } from '../store/store';
import loc, { format } from 'owa-localize';
import type { MailboxInfo } from 'owa-client-types';

/**
 * Get rollup text for current folder
 */
export default function getRollupText(
    viewType: InboxViewType,
    folderId: string,
    mailboxInfo: MailboxInfo
): string {
    const unseenCount = isFolderPausedInbox(folderId)
        ? null
        : getStore(mailboxInfo).unseenCountToDisplay;

    let rollupText;

    switch (viewType) {
        case 1:
            rollupText = format(loc(strings.focusedNewMessagesRollupText), unseenCount);
            break;
        case 2:
            rollupText = format(loc(strings.otherNewMessagesRollupText), unseenCount);
            break;
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (36,5): Type 'string | undefined' is not assignable to type 'string'.
    // @ts-expect-error
    return rollupText;
}
