import type { MailboxInfo } from 'owa-client-types';
import getGroupListNodesViewState from '../selectors/getGroupsListNodeViewState';
import type { GroupFolderNodeViewState } from '../store/schema/GroupFolderNodeViewState';

export function getGroupFolderViewState(
    groupId: string,
    folderId: string,
    mailboxInfo: MailboxInfo
): GroupFolderNodeViewState | null {
    if (groupId && folderId) {
        const groupFolderViewState = getGroupListNodesViewState(mailboxInfo)
            .groupListNodesViewStates.get(groupId.toLowerCase())
            ?.groupFolderNodeViewStates.get(folderId);

        return groupFolderViewState ?? null;
    }

    return null;
}
