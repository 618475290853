import { getShouldOverrideToSortBySizeForMailFolder } from 'owa-mail-folder-store';
import createMailFolderTableQuery from 'owa-mail-triage-table-utils/lib/createMailFolderTableQuery';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import { updateWatermarksAndResetRollup } from 'owa-mail-focused-inbox-rollup-store';
import loadTableViewFromTableQuery from '../actions/loadTableViewFromTableQuery';
import { orchestrator } from 'satcheljs';
import { selectFocusedViewFilter, getTableViewFromTableQuery } from 'owa-mail-triage-table-utils';
import { resetFocus } from 'owa-mail-focus-manager';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { lazyCreateSuggestedUnsubscribeItems } from 'owa-mail-suggested-unsubscribe-store';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';

orchestrator(selectFocusedViewFilter, async actionMessage => {
    const focusedViewFilterToSelect = actionMessage.focusedViewFilter;
    const selectedTableView = getSelectedTableView();
    const mailboxInfo = getMailboxInfo(selectedTableView);

    // Update last visit watermark for selected pivot and reset rollup data
    updateWatermarksAndResetRollup(focusedViewFilterToSelect, 'NonUserAction', mailboxInfo);

    // Always reset the view filter when user switches the focused pivot
    const shouldOverrideToSortBySize: boolean = getShouldOverrideToSortBySizeForMailFolder();
    const tableQuery = createMailFolderTableQuery(
        selectedTableView.tableQuery.folderId,
        selectedTableView.tableQuery.listViewType,
        shouldOverrideToSortBySize ? 'mailFolderSortBySize' : 'mail',
        focusedViewFilterToSelect,
        undefined /* viewFilter */,
        undefined /* categoryName */,
        undefined /* sortBy */
    );

    const loadTableViewPromise = await loadTableViewFromTableQuery(tableQuery);

    // If the user switches to the Other pivot, fetch suggested unsubscribe items
    if (isConsumer(undefined /* smtpAddress */, mailboxInfo) && focusedViewFilterToSelect == 1) {
        lazyCreateSuggestedUnsubscribeItems.importAndExecute(
            getTableViewFromTableQuery(tableQuery)
        );
    }
    resetFocus('selectFocusViewFilter');

    return loadTableViewPromise;
});
