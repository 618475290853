import type { MailboxInfo } from 'owa-client-types';
import type { GroupInformation } from '../schema/GroupInformation';
import { getGroupsState } from './getGroupsState';

export function getGroupInformation(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): GroupInformation | undefined {
    return getGroupsState(mailboxInfo).groups.get(groupSmtpAddress.toLowerCase());
}
