import type LeftNavGroupsSchema from './schema/LeftNavGroups';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type LeftNavGroupsByMailboxInfoSchema from './schema/LeftNavGroupsByMailboxInfo';

const defaultLeftNavGroupsStore: LeftNavGroupsByMailboxInfoSchema = {
    leftNavGroupsByMailboxInfo: new ObservableMap<string, LeftNavGroupsSchema>(),
};

const store = createStore<LeftNavGroupsByMailboxInfoSchema>(
    'leftNavGroupsStore',
    defaultLeftNavGroupsStore
)();
export const getLeftNavGroupsStore = () => store;
