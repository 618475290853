import type { MailboxInfo } from 'owa-client-types';
import type { GroupFolderTreeViewState } from '../store/schema/GroupFolderTreeViewState';
import getGroupListNodesViewState from '../selectors/getGroupsListNodeViewState';

export function getGroupViewState(
    groupId: string,
    mailboxInfo: MailboxInfo
): GroupFolderTreeViewState | null {
    return (
        // TODO : GroupsMultiAccount, this needs to be multi account aware.
        getGroupListNodesViewState(mailboxInfo).groupListNodesViewStates.get(
            groupId.toLowerCase()
        ) ?? null
    );
}
