import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import getGroupListNodesViewState from '../selectors/getGroupsListNodeViewState';

/**
 * Expands or collapses the group
 * @param groupId - smtp address of a group
 * @param mailboxInfo - mailbox info of the account
 */
export default mutatorAction(
    'toggleGroupListNodeExpansion',
    (groupId: string, mailboxInfo: MailboxInfo): void => {
        const groupViewState = getGroupListNodesViewState(mailboxInfo).groupListNodesViewStates.get(
            groupId.toLowerCase()
        );

        if (groupViewState) {
            groupViewState.isExpanded = !groupViewState.isExpanded;
        }
    }
);
