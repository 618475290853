import { onAddTable, onRemoveTable } from './onUpdateTables';
import { getTableViewFromTableQuery } from 'owa-mail-triage-table-utils';
import type { TableQuery } from 'owa-mail-list-store';

// This map is used to keep track of the tables that should always be in cache.
// When tables are added to this map, they are also added to the store and subscribed to.
// When tables are removed from this map, they are also removed from the store and unsubscribed from.
const currentInboxes = new Map<string, string>();

// Keep track of the folders that the table views in cache correspond to, as a
// proxy for the selectFolder code to determine if the folder contents are already
// in memory
const folderIdList: Map<string, string> = new Map<string, string>();

export function add(inboxView: string, tableQuery: TableQuery) {
    const tableView = getTableViewFromTableQuery(tableQuery);

    currentInboxes.set(inboxView, tableView.id);
    folderIdList.set(tableView.id, tableView.tableQuery.folderId);

    // Add table to store and set up row notification subscription
    onAddTable(tableView);
}

export function clear(inboxView: string) {
    const tableViewId = currentInboxes.get(inboxView);
    if (tableViewId) {
        currentInboxes.delete(inboxView);
        folderIdList.delete(tableViewId);

        // Remove table from store and unsubscribe from row notifications
        onRemoveTable(tableViewId);
    }
}

export function has(inboxView: string) {
    return currentInboxes.has(inboxView);
}

// Helper function for selectFolder to determine if the folder contents are already in memory
export function alwaysInCacheTableViewCacheHasFolderId(folderId: string): boolean {
    const folderIds = [...folderIdList.values()];
    return folderIds.includes(folderId);
}
