import type { GroupFolder } from 'owa-groups-shared-folders-schema/lib/schema/GroupFolder';
import type { FlattenedGroupFolder } from 'owa-groups-shared-folders-schema/lib/schema/FlattenedGroupFolder';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getGroupFolderChildFolderIds } from './getGroupFolderChildFolderIds';
import { getGroupFolder } from './getGroupFolder';
import type { MailboxInfo } from 'owa-client-types';

/**
 *
 * @param groupFolder The group folder
 * @param depth depth of the group folder
 * @param parentFolderDisplayName display name of the parent folder
 * @returns a group folder wrapped in FlattenedGroupFolder interface.
 */
function convertToFlattenedGroupFolder(
    groupFolder: GroupFolder,
    depth: number,
    parentFolderDisplayName: string
): FlattenedGroupFolder {
    const result: FlattenedGroupFolder = {
        ...groupFolder,
        ParentFolderDisplayName: parentFolderDisplayName,
        Depth: depth,
    };

    return result;
}

/**
 * Adds all the child folders of a given group folder
 * @param allFolderList the list that will contain all the folders of the selected group
 * @param groupId the id of the group for which the folders are fetched
 * @param depth depth to be assigned to current group folder
 * @param childFolderIds the child folder ids of the folder under consideration
 * @param parentFolderDisplayName display name of the parent folder
 * @param mailboxInfo mailbox info of the account
 */
function addAllChildFoldersToList(
    allFolderList: FlattenedGroupFolder[],
    groupId: string,
    depth: number,
    childFolderIds: string[],
    parentFolderDisplayName: string,
    mailboxInfo: MailboxInfo
) {
    for (const childFolderId of childFolderIds) {
        const groupFolder = getGroupFolder(groupId, childFolderId, mailboxInfo);

        if (groupFolder) {
            allFolderList.push(
                convertToFlattenedGroupFolder(groupFolder, depth, parentFolderDisplayName)
            );

            addAllChildFoldersToList(
                allFolderList,
                groupId,
                depth + 1,
                groupFolder.ChildFolderIds,
                groupFolder.DisplayName,
                mailboxInfo
            );
        }
    }
}

/**
 * @param groupId Smtp address of the group
 * @returns List of all the folders under the specified group
 */
export function getContextMenuGroupsFolders(groupId: string): FlattenedGroupFolder[] {
    const allFolderList: FlattenedGroupFolder[] = [];
    const initialDepth = 1;

    if (groupId) {
        groupId = groupId.toLowerCase();
        //TODO : Groups MultiAccount - Remove usage of  getGlobalSettingsAccountMailboxInfo
        const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

        const firstLevelFolderIds = getGroupFolderChildFolderIds(groupId, mailboxInfo);

        if (firstLevelFolderIds) {
            const firstLevelFolders: (GroupFolder | undefined)[] = firstLevelFolderIds.map(
                folderId => getGroupFolder(groupId, folderId, mailboxInfo)
            );

            if (firstLevelFolders) {
                firstLevelFolders.forEach(folder => {
                    if (folder) {
                        allFolderList.push(convertToFlattenedGroupFolder(folder, initialDepth, ''));

                        addAllChildFoldersToList(
                            allFolderList,
                            groupId,
                            initialDepth + 1,
                            folder.ChildFolderIds,
                            folder.DisplayName,
                            mailboxInfo
                        );
                    }
                });
            }
        }
    }

    return allFolderList;
}
