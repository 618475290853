interface GroupsWarmupData {
    warmedUpGroups: string[];
    warmUpTime: number;
}

const groupsWarmupData: GroupsWarmupData = {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (10,5): Type 'null' is not assignable to type 'string[]'.
    // @ts-expect-error
    warmedUpGroups: null,
    warmUpTime: 0,
};

export function setGroupsWarmUpTime(incomingWarmUpTime: number) {
    groupsWarmupData.warmUpTime = incomingWarmUpTime;
}

export function getGroupsWarmUpTime() {
    return groupsWarmupData.warmUpTime;
}

export function setWarmedUpGroups(groups: string[]) {
    groupsWarmupData.warmedUpGroups = groups;
}

export function wasGroupWarmedUp(groupId: string): boolean {
    const lowerCaseGroupId = groupId.toLowerCase();

    if (!groupsWarmupData.warmedUpGroups) {
        return false;
    }

    for (let i = 0; i < groupsWarmupData.warmedUpGroups.length; ++i) {
        const warmedGroupId = groupsWarmupData.warmedUpGroups[i];
        if (warmedGroupId.toLowerCase() == lowerCaseGroupId) {
            return true;
        }
    }

    return false;
}
